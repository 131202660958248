.Wrapper {
  margin-bottom: $margin40;
  overflow: hidden;
  position: relative;

  @media (min-width: $mdBreakpoint) {
    margin-bottom: 60px;
    overflow: unset;
  }
}

.SponsorBannerLogo {
  display: inline-block;
  margin-top: 10px;
  max-height: 34px;
  width: auto;
}

.PageTitle {
  composes: Title from 'LandingPage/styles.legacy.css';
  margin-bottom: 15px;
}

.Articles {
  margin-bottom: 60px;
}

.PagerWrapper {
  composes: PagerWrapper from 'LandingPage/styles.legacy.css';
}
