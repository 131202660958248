.Container {
  position: relative;
}

.AdWrapper {
  position: absolute;
  right: -8px;
  width: 0;
}

.Ad {
  background-color: $transparent;
  display: inline-block;
  position: fixed;
  top: 82px;

  /* should be over piano overlay */
  z-index: calc($zIndexPianoOverlay + 25);
}

.Sticky {
  position: fixed;
  top: calc($headerHeight + 32px);
}
