.Reply {
  border-bottom: 1px solid $greyB;
  margin-top: -16px;
  padding-bottom: 16px;
}

.Inner {
  border-left: 8px solid $greyE;
  padding-left: 16px;
}
