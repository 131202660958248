.Wrapper {
  position: relative;
}

.Breadcrumbs {
  composes: Breadcrumbs from 'App/screens/LandingPage/components/LandingPageOtherPublications/styles.legacy.css';
}

.Lead {
  color: $blackB;
  font-family: $fontGotham;
  font-size: 1.5rem;
  line-height: 1.9rem;
  margin-bottom: 71px;
  opacity: 0.7;

  @media (min-width: $smBreakpoint) {
    font-size: 1.9rem;
    line-height: 2.6rem;
    margin-bottom: $margin56;
  }

  @media (min-width: $mdBreakpoint) {
    margin-bottom: $margin48;
  }
}

.Title {
  composes: Title from 'Article/components/ArticleTitle/styles.legacy.css';
  font-size: 3.2rem;
  font-weight: 600;
  line-height: 4rem;
  margin-bottom: 52px;

  @media (min-width: $smBreakpoint) {
    font-size: 5.3rem;
    line-height: 5.8rem;
    margin-bottom: 33px;
  }
}
