.Wrapper {
  fill: $blackB;
  max-height: 76px;
  max-width: 76px;

  @media (min-width: $xlBreakpoint) {
    height: 72px;
    max-height: 72px;
    max-width: 72px;
    width: 72px;
  }
}
