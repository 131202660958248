.Inner {
  margin-bottom: $margin24;
  padding: 2px 0 24px;

  @media (min-width: $smBreakpoint) {
    padding-bottom: 34px;
    padding-top: 14px;
  }
}

.Title {
  composes: BoxTitle from 'typography.legacy.css';
  align-items: center;
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: flex-end;
  margin: 6px 0 7px;
  outline: none;
  padding: 8px 0;
  width: 100%;

  @media (min-width: $smBreakpoint) {
    margin: 0;
    padding: 26px 0;
  }
}

.Counter {
  display: inline-block;
  margin-right: $margin12;
}

.Pager {
  composes: InfoH2Grey from 'typography.legacy.css';
  align-items: center;
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  outline: none;
  padding: 32px 0;
  width: 100%;

  @media (min-width: $smBreakpoint) {
    margin-bottom: $margin32;
  }
}

.Icon {
  font-size: 15px;
  padding-left: 7px;
}
