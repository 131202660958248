.Wrapper {
  background-color: $fullScreenBg;
  color: $white;
  font-size: 40px;
  height: 100vh;
  left: 0;
  padding: 0 0 60px;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 9999;
}

.InnerWrapper {
  display: flex;
  gap: 80px;
  height: 100vh;
}

.Sidebar {
  margin-top: 127px;
}

.ListItem {
  align-items: center;
  border-left: 8px solid;
  border-radius: 0;
  border-top: 1px solid;
  display: flex;
  font-family: $fontGothamBook;
  font-size: 2rem;
  height: 54px;
  justify-content: flex-start;
  padding-left: 12px;
  white-space: nowrap;
}

.ThreeSixty {
  border-color: $threeSixty;
  color: $threeSixty;
}

.Formats {
  border-color: $formats;
  color: $formats;
}

.Mingle {
  border-color: $mingle;
  color: $mingle;
}

.Content {
  font-family: $fontGothamBook;
  font-size: 2rem;
  line-height: 1.5;
  overflow: auto;
  padding: 40px 80px 80px 0;
  width: 100%;

  header {
    font-size: 4rem;
    padding-bottom: 40px;
  }

  p {
    margin-bottom: 1.5em;
  }
}

.CloseButton {
  border: 0;
  border-radius: 50%;
  box-shadow: none;
  color: $white;
  cursor: pointer;
  display: inline-block;
  font-size: 3rem;
  outline: none;
  position: absolute;
  right: 30px;
  top: 20px;

  &::after {
    content: '\24';
    display: inline-block;
    font-family: $fontRasch;
  }
}

.Button {
  align-items: center;
  background: $nookPrimary;
  border-radius: 20px;
  color: $nookSecondary;
  cursor: pointer;
  display: flex;
  font-family: $fontGothamBook;
  font-weight: bold;
  gap: 4px;
  justify-content: center;
  padding: 4px 16px;
  position: relative;
  user-select: none;
  white-space: nowrap;
  width: fit-content;
  z-index: 2;

  &:hover {
    background: $nookPrimaryHover;
    color: $nookSecondary;
    outline: 1px solid $nookSecondary;
  }
}
