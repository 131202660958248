.CloseLink {
  cursor: pointer;
  display: inline-block;
  padding: 7px;
  position: absolute;
  right: 10px;
  top: 10px;

  @media (min-width: $smBreakpoint) {
    right: 25px;
    top: 25px;
  }
}

.Icon {
  color: $white;
  font-size: 3rem;
}
