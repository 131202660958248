.ButtonWrapper {
  display: block;
  justify-content: flex-start;
  margin-bottom: $margin16;
  position: relative;

  row-gap: $margin12;

  @media (min-width: $smBreakpoint) {
    display: flex;
    flex-wrap: wrap;
  }
}

.FilterButtonWrapper {
  column-gap: $margin12;
  display: flex;

  @media (min-width: $smBreakpoint) {
    column-gap: 0;
    flex-wrap: wrap;
  }
}

.ActionDescription {
  composes: InfoC from 'typography.legacy.css';
  margin-bottom: $margin4;
}

.Disabled {
  &.Disabled.Disabled {
    border-color: $greyB;
    color: $greyB;
    cursor: 'pointer';

    &:hover {
      border-color: $bilanzA;
      color: $bilanzA;
    }
  }
}

.Hidden {
  display: none;
}

.OptionsWrapper {
  margin-top: $margin24;
}

.OptionsHeader {
  display: flex;
  justify-content: space-between;
}

.OptionCloseIcon {
  color: $bilanzA;
  font-size: 24px;

  &:hover {
    color: $bilanzB;
    cursor: pointer;
  }
}

.HeaderTitle {
  composes: InfoCBlack from 'typography.legacy.css';
}
