.Wrapper {
  display: flex;
  justify-content: center;
}

.SearchResultWrapper {
  margin: 20px 0;
  text-align: left;

  @media (min-width: $smBreakpoint) {
    margin-top: $margin32;
  }

  @media (min-width: $mdBreakpoint) {
    margin-top: $margin40;
  }
}

.Background {
  left: 0;
  position: relative;
  right: 0;
  width: 100%;
  z-index: $zIndexHigh;
}

.ContentWrapper {
  composes: Container from '@grid.legacy.css';
  border-bottom: 1px solid $blackB;
  border-top: 1px solid $blackB;
  margin-top: 17px;

  @media (min-width: $smBreakpoint) {
    margin-top: 76px;
  }

  @media (min-width: $mdBreakpoint) {
    margin-top: $margin40;
  }
}

.Header {
  padding: 9px 0;

  @media (min-width: $smBreakpoint) {
    padding: 15px 0;
  }
}

.Subtitle {
  color: $blackB;
  font-family: $fontGothamBlack;
  font-size: 1.2rem;
  line-height: 1.7rem;

  @media (min-width: $smBreakpoint) {
    font-size: 1.5rem;
    line-height: 2rem;
    margin-bottom: 11px;
  }
}

.EmptyResultWrapper {
  font-family: $fontGothamBook;
  margin-top: 25px;
  text-align: left;

  @media (min-width: $smBreakpoint) {
    margin-top: 45px;
  }

  @media (min-width: $mdBreakpoint) {
    margin-top: 53px;
  }
}

.EmptyResultTitle {
  color: $blackB;
  font-family: $fontGothamBlack;
  font-size: 1.5rem;
  line-height: 2rem;

  @media (min-width: $smBreakpoint) {
    font-size: 2.3rem;
    line-height: 2.8rem;
  }

  @media (min-width: $mdBreakpoint) {
    font-size: 3rem;
    line-height: 3.8rem;
  }
}

.OuterWrapper {
  margin-bottom: $margin40;

  @media (min-width: $smBreakpoint) {
    margin-bottom: 60px;
  }

  @media (min-width: $mdBreakpoint) {
    margin-bottom: $margin80;
  }
}
