.Container {
  margin: 100px auto 0;
  max-width: 80%;
  text-align: center;
}

.LoadingAnimationWrapper {
  display: block;
  height: 64px;
  margin: 0 auto;
  position: relative;
  width: 64px;
}

.LoadingAnimationWrapper div {
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
  background: $logoutAnimatedDotsColor;
  border-radius: 50%;
  height: 11px;
  position: absolute;
  top: 27px;
  width: 11px;
}

.LoadingAnimationWrapper div:nth-child(1) {
  animation: loading-animation-wrapper-1 0.6s infinite;
  left: 6px;
}

.LoadingAnimationWrapper div:nth-child(2) {
  animation: loading-animation-wrapper-2 0.6s infinite;
  left: 6px;
}

.LoadingAnimationWrapper div:nth-child(3) {
  animation: loading-animation-wrapper-2 0.6s infinite;
  left: 26px;
}

.LoadingAnimationWrapper div:nth-child(4) {
  animation: loading-animation-wrapper-3 0.6s infinite;
  left: 45px;
}

@keyframes loading-animation-wrapper-1 {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes loading-animation-wrapper-3 {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(0);
  }
}

@keyframes loading-animation-wrapper-2 {
  0% {
    transform: translate(0, 0);
  }

  100% {
    transform: translate(19px, 0);
  }
}
