.Wrapper {
  align-items: center;
  background: $white;
  border: 1px solid $nookSecondary;
  border-radius: 20px;
  color: $nookSecondary;
  cursor: pointer;
  display: flex;
  font-family: $fontGothamBook;
  font-size: 1.2rem;
  font-weight: bold;
  gap: 4px;
  justify-content: center;
  line-height: 1.1;
  padding: 4px 12px 4px 20px;
  position: relative;
  user-select: none;
  z-index: 2;

  @media (min-width: $smBreakpoint) {
    bottom: 40px;
    position: fixed;
    right: 6%;
  }

  @media (min-width: $xlBreakpoint) {
    bottom: unset;
    position: relative;
    right: unset;
  }
}
