.TrendIcon {
  display: inline-block;
  font-size: 18px;

  @media (min-width: $smBreakpoint) {
    margin: 0;
  }
}

.Positive {
  color: $positiveA;
}

.Negative {
  color: $errorA;
}

.IconNew {
  transform: translate(12px, 2px);
}

.IconReturnee {
  font-size: 20px;
  transform: translate(4px, 2px);
  
  @media (min-width: $smBreakpoint) {
    font-size: 28px;
  }
}
