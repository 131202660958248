.teaser4x4 {
  /* Mobile */

  /* Make sure to change Grid0 */
  .Grid0 {
    display: grid;
    grid-column-gap: 0;
    grid-row-gap: 30px;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(1, 1fr);
    margin-bottom: 30px;
  }

  /* Tablet */
  @media (min-width: $smBreakpoint) {
    .Grid0 {
      display: grid;
      grid-column-gap: $gridGutterWidthSm;
      grid-row-gap: $margin40;
      grid-template-columns: repeat(4, 1fr);
      grid-template-rows:  auto;
      margin-bottom: $margin40;

      .Item0 { grid-area: 1 / 1 / 2 / 2; }
      .Item1 { grid-area: 1 / 2 / 2 / 3; }
      .Item2 { grid-area: 1 / 3 / 2 / 4; }
      .Item3 { grid-area: 1 / 4 / 2 / 5; }
    }
  }

  @media (min-width: $xlBreakpoint) {
    .Grid0 {
      grid-column-gap: $gridGutterWidthXl;
      grid-row-gap: 50px;
      margin-bottom: 50px;
    }
  }
}
