.DropdownViewLink {
  @mixin InfoB;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  padding: $margin24;

  @media (min-width: $smBreakpoint) {
    padding: $margin12 $margin24;
  }

  &.Active {
    @mixin InfoBBold;
  }

  &:not(.Disabled):hover {
    background-color: $greyE;
  }

  .CheckmarkIcon {
    font-size: 2.2rem;
    padding-left: 10px;
  }

  .DropdownListItem {
    align-items: center;
    display: flex;
    @mixin InfoB;


    .DescriptionFavorit {
      @mixin InfoD;
      display: block;
      max-width: 194px;
    }

    .Icons {
      font-size: 20px;
      margin-right: 10px;
      position: relative;
    }

    &.DeleteItem {
      color: $red;
    }

    &.Disabled {
      color: $greyB;
      cursor: default;
    }
  }
}
