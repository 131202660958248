.Wrapper {
  background-color: $blackB;
  height: 100%;
  inset: 0;
  opacity: 0;
  position: fixed;
  z-index: $zIndexOverlay;

  @media print {
    display: none;
  }
}

.FadeIn {
  animation: fade-in 0.3s forwards;
}

.FadeOut {
  animation: fade-out 0.3s forwards;
}

.BodyClass {
  overflow: hidden;
}

@keyframes fade-in {
  100% {
    opacity: 1;
  }
}

@keyframes fade-out {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}
