.ImageWrapper {
  line-height: 0;
  margin-bottom: 2.5rem;
  text-align: center;

  @media print {
    text-align: center;
  }
}

.ImageCaptionWrapper {
  background-color: $white;
}

.PortraitWrapper {
  background-color: $greyE;
  text-align: center;
  
  img {
    max-height: 292px;
    width: auto;

    @media (min-width: $smBreakpointTo) {
      max-height: 439px;
    }
  }
}

.PortraitWrapperHeroMedia {
  text-align: center;
}
