.OuterWrapper {
  height: 100%;
}

.Wrapper {
  column-gap: 8px;
  display: flex;
  height: 100%;
  line-height: 0;
  position: relative;
}

.ImageWrapper {
  margin-right: 12px;
  position: relative;

  width: 30%;

  @media (min-width: $smBreakpoint) {
    margin-right: 0;
  }
}

.Image {
  display: block;
  height: auto;
  width: 100%;
  z-index: calc($zIndex1 - 1);
}

.ContentWrapper {
  min-height: 100%;

  position: relative;
  width: calc(72% - 12px);

  @media (min-width: $smBreakpoint) {
    border: none;

    position: static;
    width: 100%;
  }
}

.Title {
  @media (min-width: $smBreakpoint) {
    margin-bottom: $margin8;
  }
}

.TitleInner {
  composes: H4b from 'typography.legacy.css';
  overflow-wrap: break-word;
  word-wrap: break-word;
}

.BottomLine {
  composes: Metadata from 'typography.legacy.css';
  bottom: 0;
  max-width: 100%;
  overflow: hidden;
  position: absolute;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.ShortTitle {
  composes: ShortTitleC from 'typography.legacy.css';

  margin-bottom: $margin4;
}

.ShortTitleAdvertorial {
  composes: ShortTitleD from 'typography.legacy.css';
  background-color: $decoB;
  border-radius: 4px;
  padding: 0 4px;
  width: fit-content;
}

.ShortTitleNA {
  composes: ShortTitleAdvertorial;
  background-color: $decoA;
}

.SkeletonWrapper {
  animation: pulsing 1.5s infinite;
  column-gap: 8px;

  display: flex;
  max-height: 60px;
  width: 100%;

  .ImageWrapper {
    @mixin aspectRatio1x1;

    @media (min-width: $smBreakpoint) {
      &::before {
        display: none;
      }
    }

    > img {
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;

      @media (min-width: $smBreakpoint) {
        height: auto;
        max-height: 124px;
        position: relative;

        &::before {
          display: none;
        }
      }

      @media (min-width: $mdBreakpoint) {
        max-height: 104px;
      }

      @media (min-width: $xlBreakpoint) {
        max-height: 158px;
      }
    }
  }
}

.SkeletonContentWrapper {
  height: 70px;
  position: relative;
}

.SkeletonShortTitle,
.SkeletonTitle {
  background: $grayPlaceholder;
  border-radius: 3px;
  left: 0;
}

.SkeletonShortTitle {
  height: 16px;
  max-width: 180px;
  top: 0;
  width: 100%;
}

.SkeletonTitle {
  height: 40px;
  top: 30px;
  width: 100%;
}

@keyframes pulsing {
  50% {
    opacity: 0.5;
  }
}
