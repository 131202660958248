.Title {
  color: $blackA;
  font-family: $fontGotham;
  font-size: 2.3rem;
  font-weight: bold;
  line-height: 1;
  margin-bottom: 1.5rem;
  margin-top: 0;
}

.GroupTitle {
  color: $blackA;
  font-family: $fontGotham;
  font-weight: bold;
  line-height: 1.5;
  margin-bottom: 1.5rem;
  margin-top: 0;
}

.Link {
  color: $blue;
  display: block;
  font-family: $fontGotham;
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.5;

  &:visited {
    color: $blue;
  }
}
