.LegendWrapper {
  cursor: pointer;
  display: flex;
  justify-content: flex-end;
  padding-bottom: $margin16;
  -webkit-tap-highlight-color: $transparent;
  width: 100%;
}

.Legend {
  display: flex;
  gap: 6px;
  position: relative;
}

.LegendIcon {
  color: $bilanzA;
  transform: translateY(3px);
}

.LegendLabel {
  composes: InfoC from 'typography.legacy.css';
}

.TooltipWrapper {
  background: $white;
  border-radius: 4px;
  box-shadow: 6px 6px 40px 0 $blackA2;
  display: none;
  gap: $margin8;
  padding: $margin8 $margin16;
  position: absolute;
  right: 11px;
  -webkit-tap-highlight-color: $transparent;
  top: 31px;
  z-index: 1;

  /* tooltip triangle */
  &::before {
    border-color: $transparent;
    border-bottom-color: $white;
    border-style: solid;

    /* stylelint-disable-next-line shorthand-property-no-redundant-values */
    border-width: 0 10px 13px 10px;
    content: '';
    display: none;
    height: 13px;
    position: absolute;
    right: 32px;
    top: -10px;
    width: 21px;
    z-index: 2;
  }

  &.IsOpen {
    display: grid;

    &::before {
      display: block;
    }
  }
}

.TooltipTable {
  border-collapse: separate;
  border-spacing: 4px 8px;
}

.LegendItem {
  composes: InfoC from 'typography.legacy.css';
  padding-left: 4px;
}

.LegendItemIcon {
  transform: translateX(-4px);
}

.RankingTable {
  display: block;
  width: 100%;

  @media (min-width: $smBreakpoint) {
    display: table;
    margin-top: $margin16;
  }
}

.TableHead {
  display: none;

  @media (min-width: $smBreakpoint) {
    border-radius: 4px;
    cursor: pointer;
    display: table-header-group;
    font-family: $fontGothamBlack;
    font-weight: 900;
    height: 43px;
    -webkit-tap-highlight-color: $transparent;
    text-align: center;
    text-decoration: none;
    transform: translateY(-$margin16);
    user-select: none;
  }
}

.TableHeadCell {
  background: $greyC;
  padding: 0 8px;
  text-align: left;
  vertical-align: middle;
  white-space: nowrap;

  &:first-child {
    border-bottom-left-radius: 4px;
    border-top-left-radius: 4px;
  }

  &:last-child {
    border-bottom-right-radius: 4px;
    border-top-right-radius: 4px;
  }
}

.TableHeadCellLabel {
  padding-right: 16px;
  position: relative;
  width: fit-content;
}

.SortByIcon {
  position: absolute;
  right: -2px;
  top: 8px;
}

.TableBody {
  display: flex;
  flex-wrap: wrap;
  -webkit-tap-highlight-color: $transparent;

  @media (min-width: $smBreakpoint) {
    display: table-row-group;
  }
}
