
.BodyClass {
  overflow: hidden;
  position: relative;
}

.OverlayWrapper{
  background-color: $blackAlpha5;
  inset: 0;
  opacity: 0;
  position: fixed;
  transition: opacity 300ms ease-in-out;
  z-index: -1;

  &.Open {
    opacity: 1;
    z-index: calc($zIndexHeader + 2);
  }
}

.Wrapper {
  max-width: max-content;

  @media (min-width: $smBreakpoint) {
    position: relative;
  }
}

.CloseWrapper{
  cursor: pointer;
  position: absolute;
  right: 19px;
  top: 20px;

  @media (min-width: $smBreakpoint) {
    display: none;
  }
}

.OptionsWrapper {
  background-color: $white;
  border-radius: 4px;
  box-shadow: 0 2px 12px $blackAlpha4;
  max-height: 100vh;
  position: fixed;
  text-align: left;
  transition: bottom 300ms ease-in-out;

  @media (max-width: $xsBreakpointTo) {
    border-radius: 20px 20px 0 0;
    /* stylelint-disable-next-line declaration-no-important */
    bottom: -100vh !important;
    left: 0;
    padding: 52px 0 16px;
    right: 0;
    /* stylelint-disable-next-line declaration-no-important */
    top: inherit !important;
    z-index: calc($zIndexHeader + 2);

    &.Open {
      /* stylelint-disable-next-line declaration-no-important */
      bottom: 0 !important;
    }
  }

  @media (min-width: $smBreakpoint) {
    bottom: inherit;
    left: 0;
    margin-bottom: $margin8;
    margin-top: $margin8;
    min-width: max-content;
    position: absolute;
    top: 0;
    visibility: hidden;
    z-index: $zIndex100;

    &.Open {
      visibility: visible;
    }

    &.Right {
      left: auto;
      right: 0;
    }
  }
}

.ShowMoreButton {
  composes: ButtonTertiarySmall from 'typography.legacy.css';

  align-items: center;
  background: $transparent;
  color: $blackC;
  display: flex;
  font-weight: 400;
  gap: 0 8px;
  padding-left: 0;

  &:hover {
    color: $blackA;
  }
}
