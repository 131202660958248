.SpinnerWrapper {
  margin: 10px 0;
  text-align: center;
}

.Spinner {
  animation: rotate 2s linear infinite;
  z-index: 2;
}

.Path {
  animation: dash 1.5s ease-in-out infinite;
  stroke: $blackB;
  stroke-dasharray: 1, 150;
  stroke-dashoffset: 0;
  stroke-linecap: butt;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }

  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}
