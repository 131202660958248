.Wrapper {
  background: $white;
  border: 1px solid $greyC;
  height: 100%;
  min-height: 400px;
  padding: 0 16px 16px;

  @media (min-width: $smBreakpoint) and  (max-width: $smBreakpointTo) {
    min-height: 100%;
  }
}

.Title {
  composes: BoxTitle from 'typography.legacy.css';
  color: $blackB;
  display: inline-block;
  margin: $margin16 0 $margin8;
  text-transform: uppercase;
  word-break: break-all;
}

.ChannelLink {
  composes: ButtonSmall from 'typography.legacy.css';

  color: $blueA;
  display: inline-block;
  font-weight: bold;
  margin: $margin16 0;
}

.TabWrapper {
  border-bottom: 1px solid $greyD;
  display: flex;
  flex-wrap: wrap;
  margin: 0 -$margin16;
}

.TabTitleWrapper {
  border: none;
  padding: $margin8 $margin16;

  &:focus {
    background-color: $white;
  }

  &:focus-visible {
    background-color: $greyE;
  }

  &:hover {
    background-color: $greyE;
    cursor: pointer;
  }
}

.TabTitle {
  composes: ContentBoxSmall from 'typography.legacy.css';
  color: $greyA;
}

.ActiveTab {
  border-bottom: 2px solid $blueA;

  .TabTitle {
    color: $blueA;
  }
}
