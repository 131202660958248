.teaserStage3Items {
  /* Mobile */
  .Grid0 {
    display: grid;
    grid-column-gap: 0;
    grid-row-gap: 0;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(1, auto);

    .Item {
      margin-bottom: 30px;
    }

    .Item0 { grid-area: 1 / 1 / 2 / 2; }
    .Item1 { grid-area: 2 / 1 / 3 / 2; }
    .Item2 { grid-area: 3 / 1 / 4 / 2; }
  }

  /* Tablet */
  @media (min-width: $smBreakpoint) {
    .Grid0 {
      display: grid;
      grid-column-gap: $gridGutterWidthSm;
      grid-template-columns: repeat(24, 1fr);
      grid-template-rows: 1fr;

      .Item {
        margin-bottom: $margin40;
      }

      .Item0 { grid-area: 1 / 1 / 2 / 9; }
      .Item1 { grid-area: 1 / 9 / 2 / 17; }
      .Item2 { grid-area: 1 / 17 / 2 / 25; }
    }

    @media (min-width: $mdBreakpoint) {
      .Grid0 {
        grid-column-gap: $gridGutterWidthMd;
      }
    }

    @media (min-width: $xlBreakpoint) {
      .Grid0 {
        grid-column-gap: $gridGutterWidthXl;

        .Item {
          margin-bottom: 50px;
        }
      }
    }
  }
}
