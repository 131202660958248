.Wrapper {
  align-items: center;
  background: radial-gradient(circle, $dropdownBg, $transparent);
  border-radius: 50%;
  display: flex;
  height: 200px;
  justify-content: center;
  position: absolute;
  right: 20px;
  top: -220px;
  width: 186px;

  @media (min-width: $smBreakpoint) {
    bottom: 60px;
    right: unset;
    top: unset;
  }
}
