.Sort {
  align-items: center;
  border-bottom: 1px solid $greyB;
  border-top: 1px solid $greyB;
  display: flex;
  justify-content: flex-end;
  margin: 0 0 15px;
  padding: 8px 0;

  @media (min-width: $smBreakpoint) {
    justify-content: flex-end;
    padding-bottom: 18px;
    padding-top: 18px;
  }
}

.Text {
  composes: InfoParagraphGrey from 'typography.legacy.css';
  margin-right: $margin4;

  @media (min-width: $smBreakpoint) {
    margin-right: $margin12;
  }
}

.Action {
  composes: InfoParagraphBlue from 'typography.legacy.css';
  align-items: center;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: flex-end;
  min-width: 135px;
  padding: 0;
}

.Icon {
  font-size: 15px;
  padding-left: 7px;
}
