.OuterWrapper {
  height: 100%;

  @media (min-width: $smBreakpoint) {
    height: calc(25.5vw - 15px);
  }
  @media (min-width: $mdBreakpoint) {
    height: 171px;
  }

  @media (min-width: $xlBreakpoint) {
    height: 187px;
  }
}

.Wrapper {
  background: $greyE;
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-bottom: 15px;
  padding: 0 13px;
  padding-top: 10px;

  @media (min-width: $xlBreakpoint) {
    margin-bottom: 30px;
  }
}

.ContentWrapper {
  height: 100%;
  position: relative;

  @media (min-width: $smBreakpoint) {
    height: calc(25.5vw - 15px);
  }
  @media (min-width: $mdBreakpoint) {
    height: 171px;
  }

  @media (min-width: $xlBreakpoint) {
    height: 187px;
  }
}

.Title {
  @mixin Lead1;
  color: $blueA;
  display: inline-block;
  overflow: hidden;
  word-break: break-word;

  @media (min-width: $smBreakpointTo) {
    font-size: 2rem;
    line-height: 2.6rem;
    max-height: 80px;
  }
}

.ChildWrapper {
  position: relative;
}

.LexicalEntry {
  bottom: 7px;
  color: $blackA;
  display: block;
  font-family: $fontGotham;
  font-size: 1.3rem;
  letter-spacing: 1px;
  position: absolute;
}

.ArrowWrap {
  bottom: 10px;
  color: $black;
  display: block;
  font-size: 1.7rem;
  height: 25px;
  position: absolute;
  right: 0;
  width: 25px;
}

.Arrow {
  display: none;
  left: 4px;
  position: absolute;
  top: 4px;

  @media (max-width: $smBreakpoint) {
    display: block;
  }

  @media (min-width: $lgBreakpoint) {
    display: block;
  }
}
