.Landscape {
  height: auto;
  width: 100%;
}

.ImageBox {
  background-position: 50% 70%;
  background-size: cover;
  position: relative;
  width: 100%;
}

.Upscale {
  width: 100%;
}

.Image {
  max-width: 100%;
  position: relative;
  width: auto;
}

/*
  this class is used for all blurred
  placeholders it must be unter the .Image class
*/
:global(.placeholder) {
  inset: 0;
  opacity: 1;
  position: absolute !important; /* stylelint-disable-line */
  transition: opacity 0.6s linear; /* check transition in replacePlaceholderOnLoad.js if you change this */
  width: 100% !important; /* stylelint-disable-line */
  z-index: $zIndex1;
}
