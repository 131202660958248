.BookmarksProfileWrapper {
  margin-top: $margin24;

  @media (min-width: $mdBreakpoint) {
    margin-top: $margin32;
  }
}

.LoginWrapper {
  display: flex;
}

.Title {
  composes: H1 from 'typography.legacy.css';
  margin-bottom: $margin16;
}
