@define-mixin InfoParagraphBlack {
  color: $blackB;
  font-family: $fontGothamBook;
  font-size: 1.5rem;
  line-height: 2.4rem;

  @media (min-width: $smBreakpoint) {
    font-size: 1.6rem;
    line-height: 2.6rem;
  }
}

@define-mixin Meta3 {
  color: $blackB;
  font-family: $fontGothamBook;
  font-size: 1.4rem;
  line-height: 1.9rem;
}

@define-mixin H2 {
  color: $blackB;
  font-family: $fontGothamBlack;
  font-size: 2.1rem;
  letter-spacing: -0.02px;
  line-height: 2.5rem;

  @media (min-width: $smBreakpoint) {
    font-size: 2.8rem;
    line-height: 3.2rem;
  }

  @media (min-width: $xlBreakpoint) {
    font-size: 3.5rem;
    line-height: 3.5rem;
  }
}

@define-mixin H2Center {
  @mixin H2;
  text-align: center;
}

@define-mixin H4 {
  color: $blackB;
  font-family: $fontGothamNarrow;
  font-size: 1.6rem;
  line-height: 2rem;

  @media (min-width: $xlBreakpoint) {
    font-size: 1.9rem;
    line-height: 2.3rem;
  }
}

@define-mixin Lead1 {
  color: $blackB;
  font-family: $fontGothamBook;
  font-size: 1.8rem;
  letter-spacing: -0.12px;
  line-height: 2.5rem;

  @media (min-width: $smBreakpoint) {
    font-size: 2.2rem;
    line-height: 3.2rem;
  }
}

@define-mixin ButtonBase {
  cursor: pointer;
  font-family: $fontGothamBlack;
  font-weight: 900;
  text-align: center;
  text-decoration: none;
}

@define-mixin ButtonBig {
  @mixin ButtonBase;
  font-size: 1.8rem;
  height: 54px;
  line-height: 2.2rem;
  padding: 0 24px;
}

@define-mixin ButtonSmall {
  @mixin ButtonBase;
  font-size: 1.4rem;
  height: 34px;
  line-height: 1.8rem;
  padding: 0 16px;
}

/*
 *  HZ Buttons
 *  https://rasch.invisionapp.com/console/HZ-Desktop-ckns8px1havh901yqdypz6awk/ckns8s3znldz101xazushw60y/inspect
 */

@define-mixin ButtonPrimary {
  background-color: $blueA;
  border: none;
  border-radius: 4px;
  color: $white;

  &:hover {
    background-color: $blueC;
  }
}

@define-mixin ButtonSecondary {
  background-color: $white;
  border: 1px solid $blueA;
  border-radius: 4px;
  color: $blueA;

  &:hover {
    border: 1px solid $blueC;
    color: $blueC;
  }
}

@define-mixin ButtonTertiary {
  border: none;
  color: $blueA;

  &:hover {
    color: $blueC;
  }
}

@define-mixin ButtonPrimaryBig {
  @mixin ButtonPrimary;
  @mixin ButtonBig;
}

@define-mixin ButtonPrimarySmall {
  @mixin ButtonPrimary;
  @mixin ButtonSmall;
}

@define-mixin ButtonSecondaryBig {
  @mixin ButtonSecondary;
  @mixin ButtonBig;
}

@define-mixin ButtonSecondarySmall {
  @mixin ButtonSecondary;
  @mixin ButtonSmall;
}

@define-mixin ButtonTertiaryBig {
  @mixin ButtonTertiary;
  @mixin ButtonBig;
}

@define-mixin ButtonTertiarySmall {
  @mixin ButtonTertiary;
  @mixin ButtonSmall;
}

/*
 *  BILANZ Buttons
 *  https://rasch.invisionapp.com/console/HZ-Desktop-ckns8px1havh901yqdypz6awk/ckns8s14il1z801w9xkfcjx7r/inspect
 */

@define-mixin ButtonBilanzPrimary {
  background-color: $bilanzA;
  border: none;
  border-radius: 4px;
  color: $white;

  &:hover {
    background-color: $bilanzC;
  }
}

@define-mixin ButtonBilanzSecondary {
  background-color: $white;
  border: 1px solid $bilanzA;
  border-radius: 4px;
  color: $bilanzA;

  &:hover {
    border: 1px solid $bilanzC;
    color: $bilanzC;
  }
}

@define-mixin ButtonBilanzTertiary {
  border: none;
  color: $bilanzA;

  &:hover {
    color: $bilanzC;
  }
}

@define-mixin ButtonBilanzPrimaryBig {
  @mixin ButtonBilanzPrimary;
  @mixin ButtonBig;
}

@define-mixin ButtonBilanzPrimarySmall {
  @mixin ButtonBilanzPrimary;
  @mixin ButtonSmall;
}

@define-mixin ButtonBilanzSecondaryBig {
  @mixin ButtonBilanzSecondary;
  @mixin ButtonBig;
}

@define-mixin ButtonBilanzSecondarySmall {
  @mixin ButtonBilanzSecondary;
  @mixin ButtonSmall;
}

@define-mixin ButtonBilanzTertiaryBig {
  @mixin ButtonBilanzTertiary;
  @mixin ButtonBig;
}

@define-mixin ButtonBilanzTertiarySmall {
  @mixin ButtonBilanzTertiary;
  @mixin ButtonSmall;
}

@define-mixin AdCaption {
  color: $greyA;
  font-family: $fontHelvetica;
  font-size: 1.1rem;
  font-weight: 200;
  letter-spacing: 0.1px;
  text-transform: uppercase;
}

@define-mixin FormLabelABlack {
  color: $blackA;
  font-family: $fontGothamBook;
  font-size: 1.6rem;
  line-height: 2.2rem;
}

@define-mixin FormLabelATextSecondary {
  @mixin FormLabelABlack;
  color: $greyA;
}

@define-mixin FormLabelBBlack {
  color: $blackA;
  font-family: $fontGothamBook;
  font-size: 1.2rem;
  letter-spacing: 0.3px;
  line-height: 1.6rem;
}

@define-mixin FormLabelBError {
  @mixin FormLabelBBlack;
  color: $error;
}

@define-mixin FormLabelBTextSecondary {
  @mixin FormLabelBBlack;
  color: $greyA;
}

@define-mixin RaschIconFiles {
  display: inline;
  font-family: $fontRasch;
  margin-right: 1px;
  vertical-align: top;
}

@define-mixin RichTextLink {
  border-bottom: 1px solid $blue;
  color: $blue;
  padding: 1px;

  &:not([href*='handelszeitung.ch']) {
    &:not([href^='#']) {
      &:not([href^='/']) {
        &:not([href^='mailto:']) {
          &:not([href^='tel:']) {
            &:not([class*='teaser']) {
              &:not([class*='utility-button']) {
                &:not([class*='Icon']) {
                  &::after {
                    @mixin RaschIconFiles;
                    content: '\e921';
                    margin-left: 3px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  @media print {
    border-bottom: 0;
    color: $blackB;

    &::after {
      display: none !important; /* stylelint-disable-line */
    }
  }
}

@define-mixin RankA {
  color: $blackB;
  font-family: $fontGotham;
  font-size: 3.2rem;
  font-style: normal;
  font-weight: 700;
  line-height: 3.6rem;

  @media (min-width: $smBreakpoint) {
    font-size: 1.4rem;
    font-weight: 900;
    line-height: 2.3rem;
  }
}

@define-mixin RankB {
  color: $greyA;
  font-family: $fontGothamBook;
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 1.8rem;
}

@define-mixin RankC {
  color: $blackB;
  font-family: $fontGothamBook;
  font-size: 2rem;
  font-style: normal;
  font-weight: 400;
  line-height: 2.6rem;

  @media (min-width: $smBreakpoint) {
    font-size: 1.4rem;
    line-height: 2.3rem;
  }
}

@define-mixin InfoF {
  color: $blackB;
  font-family: $fontGotham;
  font-size: 2rem;
  line-height: 2.6rem;
}

@define-mixin InfoB {
  color: $blackB;
  font-family: $fontGotham;
  font-size: 1.6rem;
  line-height: 2.1rem;
}

@define-mixin InfoBBold {
  @mixin InfoB;
  font-weight: bold;
}

@define-mixin InfoD {
  color: $blackB;
  font-family: $fontGotham;
  font-size: 1.2rem;
  line-height: 1.6rem;
}

@define-mixin RankParagraph {
  color: $blackB;
  font-family: $fontGothamBook;
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 400;
  line-height: 2.3rem;
}
