.Wrapper {
  background: color($blue shade(20%) a(30%));
  border-radius: 5px;
  color: $white;
  font-family: $fontGotham;
  font-size: 2rem;
  font-weight: 400;
  margin: 3rem auto;
  padding: 3rem;
  text-align: center;
}
