.teaser3x2 {
  /* Mobile */
  .Grid0 {
    display: grid;
    grid-column-gap: 0;
    grid-row-gap: 30px;
    grid-template-columns: repeat(24, 1fr);
    grid-template-rows: repeat(1, auto);
    margin-bottom: 30px;

    .Item0 { grid-area: 1 / 1 / 2 / 25; }
    .Item1 { grid-area: 2 / 1 / 3 / 25; }
    .Item2 { grid-area: 3 / 1 / 4 / 25; }
    .Item3 { grid-area: 4 / 1 / 5 / 25; }
    .Item4 { grid-area: 5 / 1 / 6 / 25; }
    .Item5 { grid-area: 6 / 1 / 7 / 25; }
  }

  /* Tablet */
  @media (min-width: $smBreakpoint) {
    .Grid0 {
      display: grid;
      grid-column-gap: $gridGutterWidthSm;
      grid-row-gap: $margin40;
      grid-template-columns: repeat(24, 1fr);
      grid-template-rows: repeat(1, auto);
      margin-bottom: $margin40;

      .Item0 { grid-area: 1 / 1 / 2 / 9; }
      .Item1 { grid-area: 1 / 9 / 2 / 17; }
      .Item2 { grid-area: 1 / 17 / 2 / 25; }
      .Item3 { grid-area: 2 / 1 / 3 / 9; }
      .Item4 { grid-area: 2 / 9 / 3 / 17; }
      .Item5 { grid-area: 2 / 17 / 3 / 25; }
    }
  }

  @media (min-width: $xlBreakpoint) {
    .Grid0 {
      grid-column-gap: $gridGutterWidthXl;
      grid-row-gap: 50px;
      margin-bottom: 50px;
    }
  }
}
