.HeaderWrapper {
  background-color: $greyE;
  padding-bottom: 40px;

}

.ShortTitle {
  composes: ShortTitle1TextSecondary from 'typography.legacy.css';
  display: inline-block;
  margin-bottom: $margin16;
}

.Title {
  composes: TeaserTitleH1 from 'typography.legacy.css';
  margin: 0 auto $margin8;

  @media (min-width: $xlBreakpoint) {
    margin-bottom: $margin16;
  }
}

.Lead {
  composes: Lead1 from 'typography.legacy.css';
  
  font-family: $fontGothamBook;
  font-size: 1.8rem;
  line-height: 2.5rem; 

  @media (min-width: $smBreakpoint) {
    font-size: 2.2rem;
    line-height: 3.2rem;
  }

  @media (min-width: $xlBreakpoint) {
    text-align: left !important; /* stylelint-disable-line */ /* to override compose Lead */
  }
}

.AuthorsWrapper {
  padding: 24px 0;
}

.Divider {
  background-color: $greyD;
  height: 1px;
}
