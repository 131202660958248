.Wrapper {
  composes: Clearfix from 'helpers.legacy.css';
  font-family: $fontGotham;
  hyphens: auto;
  margin-top: -1px;
  padding-top: 20px;

  @media (min-width: $mdBreakpoint) {
    padding-top: 30px;
  }
}

.Container {
  composes: Container from '@grid.legacy.css';
}

.TitleWrapper {
  width: 100%;

  @media print {
    display: none;
  }
}

.Title {
  composes: BoxTitle from 'typography.legacy.css';
}

.TeaserListSpacing {
  padding-top: 20px;
}
