.Wrapper {
  line-height: 1;
  margin-bottom: $margin16;
  padding-top: 16px;

  @media print {
    display: none;
  }

  @media (min-width: $smBreakpoint) {
    margin-bottom: $margin32;
  }
}

.Placeholder {
  line-height: 1;
  margin-bottom: $margin16;

  @media (min-width: $smBreakpoint) {
    margin-bottom: $margin32;
  }
}

.BreadcrumbList {
  align-items: center;
  display: flex;
  list-style: none;
  margin: 0 auto;
  max-width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  position: relative;
  white-space: nowrap;
  z-index: calc($zIndexLow + 1);

  @media (min-width: $mdBreakpoint) {
    align-items: flex-start;
  }
}

.BreadcrumbLink {
  composes: ImageParagraphMeta from 'typography.legacy.css';
  display: inline-block;
  padding: 0 2px;

  &:first-of-type {
    padding-left: 0;
  }

  &:last-of-type {
    padding-right: 0;
  }

  @media (min-width: $smBreakpoint) {
    font-size: 1.2rem;
  }

  &::after {
    content: '\003e';
    display: inline-block;
    padding-left: 6px;
    padding-right: 6px;
  }
}

.Title {
  composes: ImageParagraphMetaBold from 'typography.legacy.css';

  &::after {
    display: none;
  }
}
