.Wrapper {
  padding-top: 40px;

  @media (min-width: $smBreakpoint) {
    padding-bottom: 0;
  }
}

.Title {
  composes: PersonH2 from 'typography.legacy.css';
  display: block;

  @media (min-width: $smBreakpoint) {
    display: inline-block;
  }
}

.Mobile {
  composes: HiddenMdUp from '@grid.legacy.css';
  composes: PersonH2 from 'typography.legacy.css';
}

.Desktop {
  composes: HiddenLgDown from '@grid.legacy.css';
}

.ReferenceWrapper {
  display: block;
  margin-bottom: 10px;

  @media (min-width: $smBreakpoint) {
    margin-bottom: 18px;
    position: relative;
  }

  @media (min-width: $mdBreakpoint) {
    margin-bottom: 33px;
  }
}

.Reference {
  @media (min-width: $smBreakpoint) {
    bottom: 0;
    position: absolute;
    right: 0;
  }
}

.ReferenceLabel {
  color: $greyB;
  font-family: $fontGotham;
  font-size: 1.2rem;
  font-weight: 300;
  line-height: 2rem;
  margin-right: 6px;

  @media (min-width: $smBreakpoint) {
    font-size: 1.5rem;
    line-height: 2rem;
  }

  @media (min-width: $mdBreakpoint) {
    margin-right: 22px;
  }
}

.ReferenceLogo {
  bottom: -13px;
  height: 37px;
  position: relative;
  width: 37px;

  @media (min-width: $smBreakpoint) {
    bottom: 0;
    height: 54px;
    width: 54px;
  }
}

.Branch {
  composes: H4 from 'typography.legacy.css';
}

.Quantities {
  margin-top: 12px;

  @media (min-width: $smBreakpoint) {
    margin-top: 14px;
  }

  @media (min-width: $mdBreakpoint) {
    margin-top: 24px;
  }
}

.SalaryAverageWrapper,
.RatioWrapper {
  display: block;

  @media (min-width: $smBreakpoint) {
    display: inline-block;
  }
}

.SalaryAverage {
  composes: PersonQuoteBook from 'typography.legacy.css';
  display: inline-block;
}

.Currency {
  composes: PersonQuote from 'typography.legacy.css';
  display: inline-block;
  margin-left: $margin4;

  @media (min-width: $xlBreakpoint) {
    margin-left: $margin8;
  }
}

.RatioWrapper {
  @media (min-width: $smBreakpoint) {
    position: relative;
    top: 10px;
  }
}

.Ratio {
  composes: PersonQuote from 'typography.legacy.css';
  display: inline-block;

  @media (min-width: $smBreakpoint) {
    padding-left: 24px;
  }
  @media (min-width: $xlBreakpoint) {
    padding-left: 48px;
  }
}

.ShiftDownFirst {
  @media (min-width: $smBreakpoint) {
    bottom: -10px;
    position: relative;
  }
}

.ShiftDownSecond {
  @media (min-width: $smBreakpoint) {
    bottom: 0;
    position: relative;
  }
}

.RatioDescription {
  composes: InfoParagraphBlack from 'typography.legacy.css';
}

.OccupationsTitle {
  composes: H4 from 'typography.legacy.css';
  margin-bottom: 15px;
  margin-top: 37px;

  @media (min-width: $smBreakpoint) {
    margin-bottom: 14px;
    margin-top: 34px;
  }

  @media (min-width: $mdBreakpoint) {
    margin-bottom: 21px;
    margin-top: 59px;
  }

  @media (min-width: $xlBreakpoint) {
    margin-top: $margin56;
  }
}

.CardContent {
  composes: InfoParagraphBlack from 'typography.legacy.css';
  margin-bottom: 14px;
  margin-top: 3px;

  @media (min-width: $smBreakpoint) {
    margin-bottom: 25px;
    margin-top: 10px;
  }
}

.Button {
  composes: H4White from 'typography.legacy.css';
  background-color: $blackB;
  cursor: pointer;
  display: inline-flex;
  justify-content: space-between;
  margin-top: 17px;
  padding: 13px 12px 11px;
  position: relative;
  width: 100%;

  @media (min-width: $smBreakpoint) {
    float: right;
    justify-content: flex-start;
    margin-top: 43px;
    padding: 13px 13px 11px;
    width: auto;
  }

  &::after {
    color: $white;
    content: '\E903';
    display: inline-block;
    font-family: $fontRasch;
    font-size: 1.9rem;
    font-weight: 100;
    line-height: 2rem;

    @media (min-width: $smBreakpoint) {
      margin-left: $margin16;
    }
  }
}
