.Wrapper {
  margin: 0 auto;
  max-width: 800px;
  position: relative;
  width: 100%;
}

.Slide {
  video {
    pointer-events: none;
    width: 100%;
  }
}

.Footer {
  bottom: -50px;
  left: -20px;
  padding: 4px 12px;
  position: absolute;
  z-index: 2;

  h2 {
    text-transform: uppercase;
  }

  p, h2 {
    background: $black;
    font-size: 12px;
    padding: 4px 12px;

    @media (min-width: $mdBreakpoint) {
      font-size: 18px;
    }

    @media (min-width: $lgBreakpoint) {
      font-size: 22px;
    }
  }
}

.Next, .Prev {
  background: $black;
  border-radius: 50%;
  color: $nookPrimary;
  cursor: pointer;
  font-size: 40px;
  padding: 4px 12px 0;
  position: absolute;
  top: calc(50% - 30px);
  z-index: 1;
}

.Next {
  right: 20px;
}

.Prev {
  left: 20px;
}

.Play {
  align-items: center;
  background: $black;
  border-radius: 50%;
  color: $nookPrimary;
  cursor: pointer;
  display: flex;
  height: 80px;
  justify-content: center;
  left: calc(50% - 40px);
  position: absolute;
  top: calc(50% - 38px);
  width: 80px;
  
  &::before {
    content: '\f101';
    /* stylelint-disable-next-line */
    font-family: VideoJS; 
    font-size: 50px;
  }
}

.Next, .Prev, .Play {
  &:hover {
    background-color: $nookSecondary;
  }
}
