.Wrapper {
  display: block;
  position: relative;
  word-break: break-word
}

.ShortTitle {
  composes: ShortTitleBWhite from 'typography.legacy.css';
  margin-bottom: $margin4;
}

.Title {
  composes: H4White from 'typography.legacy.css';
  margin-bottom: $margin16;
}

.IsActive {
  color: $greyE;
}

.ImageWrapper {
  margin-bottom: $margin12;
  position: relative;
}

.Image {
  display: block;
  height: 100%;
  margin-right: $margin8;
  min-height: 80px;
  position: relative;
  width: 100%;
  z-index: 0;
}

.Button {
  background-color: $greyE;
  border-radius: 50%;
  height: 34px;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 34px;
  z-index: 2;

  &::after {
    border: solid 8px transparent;
    border-left: solid 12px $blackB;
    content: '';
    position: absolute;
    right: 2px;
    top: 9px;
  }
}

.ActiveButton {
  background-color: $greyB;
}
