.Wrapper {
  background-color: $dropdownBg;
  border: 1px solid $nookSecondary;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  color: $nookSecondary;
  display: flex;
  flex-wrap: wrap;
  font-family: $fontGothamBook;
  height: 0;
  left: 0;
  padding-top: 37px;
  position: absolute;
  top: 0;
  transition: height 0.3s ease, padding 0.3s ease;
  width: 100%;
  z-index: 1;

  &.Open {
    height: auto;
  }

  &.IsMobile {
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    bottom: 0;
    padding-bottom: 37px;
    padding-top: unset;
    top: unset;
  }
}

.ListItem {
  align-items: center;
  cursor: pointer;
  display: flex;
  font-size: 1.2rem;
  font-weight: 700;
  gap: 8px;
  justify-content: left;
  line-height: 1;
  padding: 6px 8px;
  position: relative;
  width: 100%;

  &.Dark {
    background: $nookSecondary;
    color: $white;

    &:hover {
      background: $dropdownItemDarkBgHover;
    }
  }

  &:not(:last-child) {
    border-bottom: 1px solid $listItemBorder;
  }

  &:hover {
    background: $nookPrimary;
  }

  a {
    color: $white;
  }

  span > img:nth-child(2) {
    position: absolute;
    right: 29px;
    top: -3px;
    width: 41px;
  }

  span > img:nth-child(3) {
    position: absolute;
    right: 0;
    top: -3px;
    width: 41px;
  }
}

.Icon {
  background: $dropdownIconBg;
  border-radius: 50%;
  min-height: 24px;
  min-width: 24px;

  &.Dark {
    background: $dropdownDarkIcon;
    color: $white;
  }

  img {
    height: 24px;
    transform: translateY(1px);
    width: 24px;
  }
}
