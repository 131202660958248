.Wrapper {
  display: flex;
  justify-content: space-between;
  list-style: none;
  max-width: 280px;

  @media (min-width: $smBreakpoint) {
    max-width: 233px;
  }

  li:last-of-type > a {
    margin-right: 0;
  }
}

.Link {
  border-radius: 50%;
  float: left;
  margin-right: 11px;
  position: relative;
  transition: opacity 0.3s ease-in;

  &:hover {
    opacity: 0.7;
  }
}

.Content {
  background-color: $white;
  border-radius: 50%;
  height: 38px;
  position: relative;
  text-align: center;
  width: 38px;
}

.Icon {
  color: $blackB;
  font-size: 2.6rem;
  line-height: 4rem;
}

/* Style for Origin Navigation */
.NavigationWrapper {
  max-width: 232px;

  .Content {
    height: 27px;
    width: 27px;
  }

  .Icon {
    color: $blackB;
    font-size: 1.8rem;
    line-height: 2.8rem;
  }

  .Link {
    margin-right: $margin24;

    &:hover {
      opacity: 1;

      .Icon {
        color: $blue;
      }
    }
  }
}
