.ExpansionPanel {
  position: relative;

  .Header {
    align-items: center;
    color: $blackB;
    cursor: pointer;
    display: flex;
    height: 50px;
    outline: none;
    position: relative;
    transition: transform 0.25s ease-in-out;
    user-select: none;
    width: 100%;
  }

  .HeaderContentWrapper {
    display: flex;
    position: relative;
  }

  .Icon {
    &::before {
      color: $blackA;
      display: inline-block;
      font-family: $fontRasch;
      font-size: 1.9rem;
    }

    &.ArrowIcon {
      align-items: center;
      display: flex;
      line-height: 1;
      transform: rotate(180deg);
      transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;

      &::before {
        content: '\e906';
      }

      @media (min-width: $smBreakpoint) {
        display: none;
      }
    }    
  }

  .Content {
    height: 0;
    overflow: hidden;
    position: relative;

    @media (min-width: $smBreakpoint) {
      /* we don't wanna toggle the menu on desktop so we overwrite the style to not hide on state change */
      height: auto !important; /* stylelint-disable-line */
    }
  }

  .Title {
    color: $blackA;
    font-family: $fontGothamBlack;
    font-size: 1.7rem;
    line-height: 2.2rem;
    margin-right: 9px;
    text-transform: uppercase;
  }

  &.IsOpen {
    .ArrowIcon {
      transform: rotate(0deg);
    }
  }
}
