.HeadWrapper {
  background-color: $greyE;
}

.HeaderWrapper {
  margin-bottom: $margin24;
  padding-bottom: 24px;

  @media (min-width: $mdBreakpoint) {
    margin-bottom: $margin32;
    padding-bottom: 32px;
  }
}

.Category {
  composes: H5Light from 'typography.legacy.css';
  margin-bottom: $margin8;
}

.Title {
  composes: KeywordsH1 from 'typography.legacy.css';
}


.TitleWrapper {
  @media (min-width: $smBreakpoint) { 
    margin-bottom: $margin16;
  }

  @media (min-width: $xlBreakpoint) { 
    margin-bottom: $margin24;
  }
}

.UtilityBarWrapper {
  @mixin hideforPrint;
  background-color: $white;
  border-top: 1px solid $greyC;
  bottom: 0;
  height: 70px;
  left: 0;
  position: fixed;
  right: 0;
  z-index: $zIndexHighest;

  @media (min-width: $smBreakpoint) {
    background-color: $transparent;
    border-top: 0;
    height: auto;
    position: relative;
  }
}

.ArticleBody {
  :global(#app) {
    padding-bottom: 70px;

    @media (min-width: $smBreakpoint) {
      padding-bottom: 0;
    }
  }
}

