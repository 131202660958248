.latestHeadlessStories {
  /* Mobile */

  .Grid0 {
    display: grid;
    grid-column-gap: 0;
    grid-row-gap: 30px;
    grid-template-columns: repeat(24, 1fr);
    grid-template-rows: repeat(4, 1fr);
    margin-bottom: 30px;

    .Item0 { grid-area: 1 / 1 / 2 / 25; }
    .Item1 { grid-area: 2 / 1 / 3 / 25; }
    .Item2 { grid-area: 3 / 1 / 4 / 25; }
    .Item3 { grid-area: 4 / 1 / 5 / 25; }
  }

  /* Tablet */
  @media (min-width: $smBreakpoint) {
    .Grid0 {
      display: grid;
      grid-column-gap: $gridGutterWidthSm;
      grid-row-gap: 0;
      grid-template-columns: repeat(24, 1fr);
      grid-template-rows: 1fr;
      margin-bottom: 0;
      
      .Item0 { grid-area: 1 / 1 / 2 / 7; }
      .Item1 { grid-area: 1 / 7 / 2 / 13; }
      .Item2 { grid-area: 1 / 13 / 2 / 19; }
      .Item3 { grid-area: 1 / 19 / 2 / 25; }
    }

    /* Desktop */
    @media (min-width: $xlBreakpoint) {
      .Grid0 {
        grid-column-gap: $gridGutterWidthXl;
      }
    }
  }
}
