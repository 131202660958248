.Button {
  align-items: center;
  background: $nookSecondary;
  border-radius: 20px;
  bottom: 0;
  color: $white;
  cursor: pointer;
  display: flex;
  font-family: $fontGothamBook;
  font-size: 1.2rem;
  font-weight: bold;
  gap: 4px;
  justify-content: center;
  line-height: 1;
  padding: 4px 12px;
  position: absolute;
  user-select: none;
  z-index: 2;

  &:hover {
    background: $nookPrimary;
    color: $nookSecondary;
    outline: 1px solid $nookSecondary;
  }
}

.Icon {
  background-color: $white;
  border-radius: 50%;
  margin-right: 20px;
  min-height: 24px;
  min-width: 24px;

  img {
    height: 24px;
    transform: translateY(1px);
    width: 24px;
  }
}

.LikeButton {
  cursor: pointer;
  height: 12px;
  left: 20px;
  position: absolute;
  top: 20px;
  width: 12px;
}

.Lottie {
  position: absolute;
  top: -4px;
}
