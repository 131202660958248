.Wrapper {
  line-height: 0;
  margin: 5px auto;
  width: 100%;

  @media print {
    display: none;
  }
}

.Video {
  height: 157px;
  width: 100%;

  @media (min-width: $smBreakpoint) {
    height: 394px;
  }

  @media (min-width: $mdBreakpoint) {
    height: 497px;
  }

  @media (min-width: $xlBreakpoint) {
    height: 634px;
  }

  @media print {
    display: none;
  }
}

.SponsorBannerLogo {
  display: block;
  margin-right: 10px;
  max-height: 30px;
  width: auto;
}
