.Wrapper {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 2.5rem;
  max-width: 100%;
  overflow: hidden;

  /* required because otherwise infogram embeds are rendered with a width of 0px?! */
  & > div {
    width: 100%;
  }

  & :is(h1, h2, h3, h4, h5, h6, span, p) {
    align-self: flex-start;
  }

  &.WrapperNoOverflow {
    overflow: visible;
  }
}

.Placeholder {
  border-radius: 4px;
  box-shadow: rgb(0 0 0 / 50%) 0 0 1px 0, rgb(0 0 0 / 15%) 0 1px 10px 0;
  color: $smokeyWhite;
  font-size: 1.5rem;
  font-weight: 100;
  line-height: 200px;
  margin: 1px;
  padding: 0;
  text-align: center;
  width: calc(100% - 2px);
}

:global(.mobile-hidden) {
  display: none !important; /* stylelint-disable-line */
  @media (min-width: $smBreakpoint) {
    display: inline-block !important; /* stylelint-disable-line */
  }
}

:global(.desktop-hidden) {
  display: inline-block !important; /* stylelint-disable-line */
  @media (min-width: $smBreakpoint) {
    display: none !important; /* stylelint-disable-line */
  }
}
