.Wrapper {
  margin-bottom: $margin40;
  overflow: hidden; /* to hide not visible part of sky ad */
  position: relative;

  &::after,
  &::before {
    content: '';
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: $zIndexBackground;
  }

  @media (min-width: $mdBreakpoint) {
    margin-bottom: 60px;
  }
}

.Wrapper::after {
  opacity: 0.45;
}

.OuterWrapper {
  padding-bottom: 8px;
  position: relative;

  @media (min-width: $smBreakpoint) {
    padding-bottom: 24px;
  }

  @media (min-width: $mdBreakpoint) {
    padding-bottom: 40px;
  }
}

.InnerWrapper {
  height: 100%;
  justify-content: space-between;
  position: relative;
  z-index: $zIndexMedium;
}

.HeaderBackground {
  background-color: $greyE;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;

  @media print {
    background-image: none;
  }
}

.Title {
  composes: PersonH1 from 'typography.legacy.css';
}

.ShortTitle {
  composes: ShortTitle from 'typography.legacy.css'; /* check font style with UX */
  margin: 10px 0;
  text-transform: uppercase;

  @media (min-width: $smBreakpoint) {
    margin-top: 30px;
  }

  @media (min-width: $mdBreakpoint) {
    margin-top: 34px;
  }
}

.SalaryCheckerWrapper {
  padding-top: 40px;

  @media (min-width: $smBreakpoint) {
    padding-bottom: 0;
  }
}
