.teaserSM {
  /* Mobile */
  .Grid0 {
    display: grid;
    grid-column-gap: 0;
    grid-row-gap: 16px;
    grid-template-columns: repeat(24, 1fr);
    grid-template-rows: repeat(1, auto);

    .Item0 { grid-area: 1 / 1 / 2 / 25; }
    .Item1 { grid-area: 2 / 1 / 3 / 25; }
    .Item2 { grid-area: 3 / 1 / 4 / 25; }
  }

  /* Tablet */
  @media (min-width: $smBreakpoint) {
    .Grid0 {
      display: grid;
      grid-column-gap: 16px;
      grid-template-columns: repeat(8, 1fr);
      grid-template-rows: repeat(1, auto);

      .Item0 { grid-area: 1 / 1 / 2 / 9; }
      .Item1 { grid-area: 2 / 1 / 2 / 9; }
      .Item2 { grid-area: 3 / 1 / 3 / 9; }
    }
  }

  @media (min-width: $xlBreakpoint) {
    .Grid0 {
      display: grid;
      grid-template-columns: repeat(7, 1fr);
      grid-template-rows: repeat(1, auto);

      .Item0 { grid-area: 1 / 1 / 2 / 8; }
      .Item1 { grid-area: 2 / 1 / 2 / 8; }
      .Item2 { grid-area: 3 / 1 / 3 / 8; }
    }
  }
}
