/* stylelint-disable keyframes-name-pattern */

@define-mixin BellAnimation {
  @keyframes BellAnimation {
    0% {
      transform: rotate(10deg);
    }

    25% {
      transform: rotate(-10deg);
    }

    50% {
      transform: rotate(20deg);
    }

    75% {
      transform: rotate(-5deg);
    }

    100% {
      transform: rotate(0deg);
    }
  }
}

@define-mixin ScaleAnimation {
  @keyframes ScaleAnimation {
    0% {
      transform: scale(1);
    }

    25% {
      transform: scale(1.1);
    }

    50% {
      transform: scale(1.3);
    }

    75% {
      transform: scale(1.1);
    }

    100% {
      transform: scale(1);
    }
  }
}

@define-mixin ErrorMessageEnterAnimation {
  @keyframes ErrorMessageEnterAnimation {
    0% {
      transform: scale(1, 0) translateY(-100%);
    }

    100% {
      transform: scale(1, 1) translateY(0);
    }
  }
}

@define-mixin LabelAboveShakingEffectAnimation {
  @keyframes LabelAboveShakingEffectAnimation {
    0% {
      transform: scale(0.7) translate(0, -18px);
    }

    50% {
      transform: scale(0.7) translate(5px, -18px);
    }

    100% {
      transform: scale(0.7) translate(0, -18px);
    }
  }
}

@define-mixin LabelShakingEffectAnimation {
  @keyframes LabelShakingEffectAnimation {
    0% {
      transform: translateX(0);
    }

    50% {
      transform: translateX(5px);
    }

    100% {
      transform: translateX(0);
    }
  }
}
