.Wrapper {
  line-height: 0;
  margin: 0 auto;
  position: relative;
  width: 100%;
}

.Img {
  margin-bottom: 6px;
  width: 100%;

  @media (min-width: $smBreakpoint) {
    margin-bottom: $margin8;
  }
}

.SponsorBannerLogo {
  display: block;
  margin-right: 10px;
  max-height: 30px;
  width: auto;
}
