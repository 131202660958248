.Border {
  border-left: 20px solid $greyE;
  padding: $defaultPadding;
  position: relative;

  @media (min-width: $smBreakpoint) {
    border-left: 22px solid $greyE;
  }

  &::before {
    background: $white;
    content: '';
    display: block;
    height: calc(100% - 90px);
    left: 0;
    position: absolute;
    top: 60px;
    width: 100%;
  }

  .Devider {
    background-color: $blackB;
    height: 6px;
    left: 8px;
    position: absolute;
    top: 0;
    width: 55px;

    @media (min-width: $smBreakpoint) {
      height: 8px;
      left: 17px;
      width: 95px;
    }
  }

  .ParagraphWrapper {
    background-color: $white;
    margin-bottom: -4px;
    margin-left: -24px;
    margin-top: -4px;
    padding: 27px 0 1px 23px;
    position: relative;

    img {
      height: auto;
      max-width: 100%;
    }

    @media (min-width: $smBreakpoint) {
      margin-bottom: -2px;
      margin-top: -2px;
      padding: 45px 0 1px 42px;
    }
  }
}

.Typography {
  composes: Typography from '../../styles.legacy.css';
  margin-bottom: 0;

  h2 {
    color: $blackB;
    font-family: $fontGothamNarrow;
    font-size: 2.1rem;
    letter-spacing: -0.02px;
    line-height: 2.5rem;

    @media (min-width: $smBreakpoint) {
      font-size: 2.8rem;
      line-height: 3.2rem;
    }

    @media (min-width: $mdBreakpoint) {
      font-size: 3.5rem;
      line-height: 3.5rem;
    }
  }

  h3 {
    color: $blackB;
    font-family: $fontGothamNarrow;
    font-size: 1.9rem;
    line-height: 2.3rem;

    @media (min-width: $mdBreakpoint) {
      font-size: 2.4rem;
      letter-spacing: -0.03px;
      line-height: 2.6rem;
    }
  }

  h4 {
    color: $blackB;
    font-family: $fontGothamBlack;
    font-size: 1.4rem;
    line-height: 1.8rem;

    @media (min-width: $smBreakpoint) {
      font-size: 1.6rem;
      line-height: 2rem;
    }

    @media (min-width: $mdBreakpoint) {
      font-size: 1.9rem;
      line-height: 2.3rem;
    }
  }

  h5 {
    color: $blackB;
    font-family: $fontGothamBlack;
    font-size: 1.3rem;
    letter-spacing: 1px;
    line-height: 1.4rem;
  }

  p {
    color: $blackB;
    font-family: $fontGothamBook;
    font-size: 1.5rem;
    line-height: 2.4rem;

    @media (min-width: $smBreakpoint) {
      font-size: 1.6rem;
      line-height: 2.6rem;
    }
  }
}

.SectionMargin {
  margin-bottom: $margin24 !important; /* stylelint-disable-line */

  @media (min-width: $smBreakpoint) {
    margin-bottom: $margin32 !important; /* stylelint-disable-line */
  }
}
