.Wrapper {
  padding-top: 40px;

  @media (min-width: $smBreakpoint) {
    padding-bottom: 50px;
    padding-top: 56px;
  }
}

.Title {
  composes: PersonH2 from 'typography.legacy.css';
  margin-bottom: 7px;
}

.ReferenceWrapper {
  display: block;
  margin-bottom: 10px;

  @media (min-width: $smBreakpoint) {
    margin-bottom: 27px;
    position: relative;
    top: -8px;
  }
}

.Reference {
  composes: Reference from 'SalaryCheckerPerBranch/styles.legacy.css';
}

.ReferenceLabel {
  composes: ReferenceLabel from 'SalaryCheckerPerBranch/styles.legacy.css';
}

.ReferenceLogo {
  bottom: -5px;
  height: 19px;
  position: relative;
  width: 82px;

  @media (min-width: $smBreakpoint) {
    bottom: -6px;
    height: 25px;
    width: 110px;
  }
}

.PositionsWrapper {
  margin-bottom: 10px;

  @media (min-width: $smBreakpoint) {
    margin-bottom: $margin32;
  }

  @media (min-width: $mdBreakpoint) {
    margin-bottom: 44px;
  }
}

.PositionsCount {
  composes: PersonQuoteBook from 'typography.legacy.css';
  display: table-cell;
}

.PositionsLabel {
  composes: InfoParagraphBlack from 'typography.legacy.css';
  display: inline-block;
  padding-left: 7px;

  @media (min-width: $smBreakpoint) {
    padding-left: 18px;
  }
}

.Button {
  composes: Button from 'SalaryCheckerPerBranch/styles.legacy.css';
  composes: H4White from 'typography.legacy.css';
}

.CardWrapper {
  @media (min-width: $smBreakpoint) and (max-width: $smBreakpointTo) {
    > div:nth-child(3) {
      display: none;
    }
  }
}
