.HeaderSection {
  background-color: $greyE;
}

.TitleWrapper {
  align-items: center;
  display: flex;
}

.Title {
  composes: KeywordsH1 from 'typography.legacy.css';
  margin-bottom: $margin32;
}
