.Wrapper {
  composes: Clearfix from 'helpers.legacy.css';
  position: relative;

  @media print {
    display: none;
  }
}

.Slide {
  align-items: center;
  display: inline-flex;
  height: 100%;
  overflow: hidden;
  text-align: center;
  vertical-align: middle;
  width: 100%;
}
