.Wrapper {
  padding-bottom: 40px;
  position: relative;

  @media (min-width: $mdBreakpoint) {
    /* width >= 960 */
    padding-bottom: 60px;
  }

  &.WithGreyBg {
    background: $greyE;
  }
}

.HeroWrapper {
  align-items: center;
  background: $blackB;
  display: flex;
  flex-direction: column;
  gap: $margin40;
  justify-content: center;
  padding-top: $margin40;

  @media (min-width: $mdBreakpoint) {
    flex-direction: row;
    padding: $margin40 0;
  }

  @media (min-width: $lgBreakpoint) {
    padding: $margin80 0;
  }

  /* hack to vertically align our hero content */
  & > div > div > div {
    align-items: center;
    display: flex;
    justify-content: center;
  }
}

.HeroInnerWrapper {
  align-items: center;
  display: flex;
  flex-direction: column;
  padding-bottom: 40px;
  text-align: center;

  @media (min-width: $mdBreakpoint) {
    align-items: flex-start;
    margin-right: 20px;
    padding: 0;
    text-align: left;
    width: 100%;
  }
}

.ShortTitle {
  composes: ShortTitleA from 'typography.legacy.css';
  color: $white;
  margin-bottom: $margin24;
}

.ShortTitleLegacy {
  composes: Lead from 'OverviewPageHeader/components/Bilanz/styles.legacy.css';
  margin: 0;

  a {
    border-bottom: 1px solid $blue;
    color: $blue;
  }
}

.HeroTitle {
  composes: H1 from 'typography.legacy.css';
  color: $white;
  margin-bottom: $margin24;
}

.HeroLead {
  composes: Lead1 from 'typography.legacy.css';
  color: $white;
  margin-bottom: 0;
}

.HeroImageWrapper {
  @media (min-width: $mdBreakpoint) {
    align-items: center;
    display: flex;
  }
}

.HeroImage {
  margin: 0 calc(50% - 50vw);
  width: 100vw;

  @media (min-width: $mdBreakpoint) {
    margin: 0;
    width: 100%;
  }
}


.Header {
  background-color: $greyE;
  height: 100%;
  left: 0;
  padding-bottom: 100px;
  right: 0;
  top: 0;
  z-index: $zIndexLow;

  &::before {
    content: '';
    display: block;
    height: 100%;
    width: 100%;
  }

  @media print {
    background-image: none;
  }

  @media (min-width: $smBreakpoint) {
    padding-bottom: 200px;
  }

  @media (min-width: $mdBreakpoint) {
    padding-bottom: 220px;
  }
}

.TeaserWrapperLegacy {
  margin-top: -75px;

  @media (min-width: $smBreakpoint) {
    margin-top: -155px;
  }
}

.RankingsWrapper {
  padding-top: 20px;

  @media (min-width: $smBreakpoint) {
    background: $white;
  }
}

.Title {
  composes: Title from 'OverviewPageHeader/components/Bilanz/styles.legacy.css';
  margin-left: 0;
}

.ParagraphsContainer {
  padding-top: 40px;

  @media (min-width: $mdBreakpoint) {
    padding-top: 120px;
  }
}

.Positive {
  color: $positiveA;
}

.Negative {
  color: $errorA;
}

.EmptyResultsWrapper {
  composes: InfoB from 'typography.legacy.css';
  margin: 50px 0;
  text-align: center;
}
