.Wrapper {
  margin-bottom: $margin40;
  min-height: 200px;
  overflow: hidden;
  position: relative;

  @media (min-width: $mdBreakpoint) {
    margin-bottom: 60px;
    min-height: 500px;
  }
}

.HeaderBackground {
  background: $greyE;
  margin-bottom: 26px;
  padding-bottom: 16px;
  padding-top: 6px;

  @media (min-width: $smBreakpoint) {
    margin-bottom: 36px;
    padding-bottom: 32px;
    padding-top: 7px;
  }

  @media (min-width: $mdBreakpoint) {
    margin-bottom: 46px;
    padding-top: 0;
  }
}

.BrandReportWrapper {
  display: flex;
  justify-content: flex-start;
  @media (min-width: $smBreakpoint) {
    justify-content: center;
    padding-top: 13px;
  }

  @media (min-width: $mdBreakpoint) {
    padding-top: 11px;
  }
}

.BrandReportLogoWrapper {
  height: 39px;
  margin-bottom: 10px;
  width: 188px;

  @media (min-width: $smBreakpoint) {
    height: 55px;
    margin-bottom: 20px;
    width: 266px;
  }

  @media (min-width: $mdBreakpoint) {
    height: 58px;
    width: 279px;
  }
}

.SecondaryTitle {
  composes: InfoH1Black from 'typography.legacy.css';
  padding-bottom: 10px;

  @media (min-width: $smBreakpoint) {
    padding-bottom: 15px;
    text-align: center;
  }
}

.HeaderDescription {
  composes: InfoParagraphBlack from 'typography.legacy.css';

  @media (min-width: $smBreakpoint) {
    margin: 0 auto;
    text-align: center;
    width: 95%;
  }

  @media (min-width: $mdBreakpoint) {
    width: 85%;
  }

  @media (min-width: $xlBreakpoint) {
    width: 65%;
  }
}

.TeaserWrapper {
  margin-bottom: 30px;

  @media (min-width: $smBreakpoint) {
    margin-bottom: 40px;
  }

  @media (min-width: $xlBreakpoint) {
    margin-bottom: 50px;
  }
}

.AdContainer {
  composes: AdContainer from 'helpers.legacy.css';
  align-items: center;
  background: $greyE;
  display: flex;
  height: 100%;
  justify-content: center;
  margin: 0 auto 30px;
  position: relative;

  @media (min-width: $smBreakpoint) {
    display:none;
  }
}

