.Wrapper {
  display: flex;

  &.Open {
    position: relative;
  }
}

.Button {
  align-items: center;
  background: $nookSecondary;
  border-radius: 20px;
  color: $nookPrimary;
  cursor: pointer;
  display: flex;
  font-family: $fontGothamBook;
  font-weight: bold;
  gap: 4px;
  justify-content: center;
  padding: 4px 12px;
  position: relative;
  user-select: none;
  width: 186px;
  z-index: 2;

  &:hover {
    background: $nookPrimary;
    color: $nookSecondary;
    outline: 1px solid $nookSecondary;
  }
}

.Icon {
  font-size: 2.2rem;
  transform: rotate(0deg);
  transition: transform 0.3s ease;

  &.IsMobile {
    transform: rotate(180deg);
  }
}
