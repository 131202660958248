.Wrapper {
  padding-top: 20px;

  @media (min-width: $smBreakpoint) {
    padding-top: 55px;
  }
}

.Title {
  composes: PersonH2 from 'typography.legacy.css';
  display: block;
  padding-bottom: 8px;

  @media (min-width: $smBreakpoint) {
    display: inline-block;
  }
}

.ReferenceWrapper {
  display: none;
  margin-bottom: 12px;

  @media (min-width: $smBreakpoint) {
    display: block;
    margin-bottom: 20px;
    position: relative;
    top: -8px;
  }
}

.Reference {
  @media (min-width: $smBreakpoint) {
    bottom: 0;
    position: absolute;
    right: 0;
  }
}

.ReferenceLabel {
  composes: ImageParagraphMeta from 'typography.legacy.css';
  margin-right: 6px;
}

.ReferenceLogo {
  bottom: -13px;
  height: 37px;
  position: relative;
  width: 37px;

  @media (min-width: $smBreakpoint) {
    bottom: -4px;
    height: 28px;
    width: 173px;
  }
}

.TableWrapper {
  display: flex;
  flex-flow: row wrap;

  @media (min-width: $smBreakpoint) {
    display: flex;
  }
}

.Header {
  composes: InfoParagraphBlackUppercase from 'typography.legacy.css';
  border-bottom: 1px solid $blackB;
  display: none;
  padding-bottom: 5px;

  @media (min-width: $smBreakpoint) {
    display: block;
  }
}

.Button {
  composes: H4White from 'typography.legacy.css';
  background-color: $blueA;
  cursor: pointer;
  display: inline-flex;
  font-size: 1.4rem;
  justify-content: space-between;
  margin-top: 17px;
  padding: 13px 12px 11px;
  position: relative;
  width: 100%;

  @media (min-width: $smBreakpoint) {
    float: right;
    justify-content: flex-start;
    margin-top: 43px;
    padding: 13px 13px 11px;
    width: auto;
  }

  &::after {
    color: $white;
    content: '\E903';
    display: inline-block;
    font-family: $fontRasch;
    font-size: 1.9rem;
    font-weight: 100;
    line-height: 2rem;

    @media (min-width: $smBreakpoint) {
      margin-left: $margin16;
    }
  }
}
