.Wrapper {
  composes: Container from '@grid.legacy.css';
}

.MainContent {
  /* placeholder */
}

.AsideContent {
  @media (min-width: $smBreakpoint) {
    margin-top: 63px;
  }
}
